import React from "react";
import { Sider, Menu } from "../../components/shared";

import "./navbar.scss";
import { TableOutlined } from "@ant-design/icons";
import { Liveline, LivelineMobile, alarmModule } from "../../assets/dark-theme";

import {
    monitorRules,
    reporting,
    MLAdmin,
    predictiveAlarmsAdmin,
    monitorStack,
    graphsAndCharts,
    impactKPI,
    DPK,
    LPP,
    LCPTrained,
    backTest,
    ghostScheduler,
    lcpPerformance,
    predictiveAlarmsMonitor,
    ML_Control_1,
    CodeOutlined,
    LineChartOutlined,
    MenuUnfoldOutlined,
    PieChartOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {
    users,
    Signal_Data_New_1,
    VML_1,
    admin,
    cells,
    controls,
    dashboard,
    equipment,
    facilities,
    historicalRunsIcon,
    liveHistoricalData,
    plug,
    products,
    rulesAndConfiguration,
    matrix,
    Alert
} from "../../assets/dark-theme";

const menuItems = [
    {
        label: "Dashboard",
        key: "dashboard",
        title: "Dashboard",
        icon: <img src={dashboard} alt="Dashboard" className="sideMenuLogo" />,
    },
    {
        label: "Cell Dashboard",
        key: "cell-dashboard",
        title: "Cell Dashboard",
        icon: (
            <PieChartOutlined
                className="sideMenuLogo"
                style={{ fontSize: "30px", color: "#c9c9c9" }}
            />
        ),
    },
    {
        label: "Operator",
        key: "operator",
        title: "Operator",
        icon: (
            <MenuUnfoldOutlined
                className="sideMenuLogo"
                style={{ fontSize: "30px", color: "#c9c9c9" }}
            />
        ),
    },
    {
        label: "Analytics",
        key: "oee-console",
        title: "Analytics",
        icon: (
            <LineChartOutlined
                className="sideMenuLogo"
                style={{ fontSize: "30px", color: "#c9c9c9" }}
            />
        ),
    },
    {
        label: "Model Metrics",
        key: "model-metrics",
        title: "Model Metrics",
        icon: (
            <CodeOutlined
                className="sideMenuLogo"
                style={{ fontSize: "30px", color: "#c9c9c9" }}
            />
        ),
    },
    {
        label: "Alert Log",
        key: "alert-log",
        title: "Alert Log",
        icon: (
            <WarningOutlined
                className="sideMenuLogo"
                style={{ fontSize: "30px", color: "#c9c9c9" }}
            />
        ),
    },
    {
        label: "Admin",
        key: "general_admin",
        title: "Admin",
        icon: <img src={admin} alt="Admin" className="sideMenuLogo" />,
        children: [
            {
                label: "Facilities",
                key: "general_admin/facilities",
                title: "Facilities",
                icon: (
                    <img
                        src={facilities}
                        alt="Facilities"
                        className="sideMenuLogo"
                    />
                ),
            },
            {
                label: "Cells",
                key: "general_admin/cells",
                title: "Cells",
                icon: <img src={cells} alt="Cells" className="sideMenuLogo" />,
            },
            {
                label: "Equipment",
                key: "general_admin/equipment",
                title: "Equipment",
                icon: (
                    <img
                        src={equipment}
                        alt="equipment"
                        className="sideMenuLogo"
                    />
                ),
            },
            {
                label: "Products",
                key: "general_admin/products",
                title: "Products",
                icon: (
                    <img
                        src={products}
                        alt="products"
                        className="sideMenuLogo"
                    />
                ),
            },
            {
                label: "Users",
                key: "general_admin/users",
                title: "Users",
                icon: (
                    <img
                        src={users}
                        alt="users"
                        className="sideMenuLogo"
                        height={26}
                    />
                ),
            },
        ],
    },
    {
        label: "Monitor",
        key: "live_historical_data",
        title: "Monitor",
        icon: (
            <img
                src={liveHistoricalData}
                alt="liveHistoricalData"
                className="sideMenuLogo"
            />
        ),
        children: [
            {
                label: "Signal Data",
                key: "live_historical_data/monitor_live_data",
                title: "Signal Data",
                icon: (
                    <img
                        src={Signal_Data_New_1}
                        alt="monitorSignalData"
                        className="sideMenuLogo"
                    />
                ),
            },
            // {
            //     label: 'Rules Control',
            //     key: 'live_historical_data/monitor_rules',
            //     title: 'Rules Control',
            //     icon: <img src={monitorRules} alt='monitorRules' className='sideMenuLogo' />,
            // },
            // {
            //     label: 'ML Control',
            //     key: 'live_historical_data/monitor_machine_learning',
            //     title: 'ML Control',
            //     icon: <img src={ML_Control_1} alt='monitorMlControl' className='sideMenuLogo' />,
            // },
            {
                label: "Live Services",
                key: "live_historical_data/live_services",
                title: "Live Services",
                icon: (
                    <img
                        src={plug}
                        alt="liveServices"
                        className="sideMenuLogo"
                    />
                ),
            },
            {
                label: "Historical Runs",
                key: "live_historical_data/historical_runs",
                title: "Historical Runs",
                icon: (
                    <img
                        src={historicalRunsIcon}
                        alt="historicalRuns"
                        className="sideMenuLogo"
                    />
                ),
            },
        ],
    },
    // {
    //     label: 'Reporting',
    //     key: 'reporting',
    //     title: 'Reporting',
    //     icon: <img src={reporting} alt='reporting' className='sideMenuLogo' />,
    //     children: [
    //         {
    //             label: 'Run Reports',
    //             title: 'Run Reports',
    //             key: 'reporting/graphs_charts',
    //             icon: <img src={graphsAndCharts} alt='graphsAndCharts' className='sidemenulogo' />,
    //         },
    //         {
    //             label: 'Impact KPI',
    //             key: 'reporting/impact_kpi',
    //             title: 'Impact KPI',
    //             icon: <img src={impactKPI} alt='impactKPI' className='sideMenuLogo' />,
    //         },
    //     ],
    // },
    {
        label: "Controls",
        key: "controls_admin",
        title: "Controls",
        icon: <img src={controls} alt="controls" className="sideMenuLogo" />,
        children: [
            {
                label: "Rules Configuration",
                key: "controls_admin/rules_configuration",
                title: "Rules Configuration",
                icon: (
                    <img
                        src={rulesAndConfiguration}
                        alt="rulesAndConfiguration"
                        className="sideMenuLogo"
                    />
                ),
            },
            {
                label: "Alerts Configuration",
                key: "controls_admin/alerts_configuration",
                title: "Alerts Configuration",
                icon: (
                    <img
                        src={Alert}
                        alt="alertsAndConfiguration"
                        className="sideMenuLogo"
                    />
                ),
            },
            {
                label: "ML Configuration",
                key: "controls_admin/ml_configuration",
                title: "ML Configuration",
                icon: (
                    <img src={VML_1} alt="MLConfig" className="sideMenuLogo" />
                ),
            },
            {
                label: "State Action Matrix",
                key: "controls_admin/state_action_matrix",
                title: "State State Action Matrix",
                icon: (
                    <img
                        src={matrix}
                        alt="rulesAndConfiguration"
                        className="sideMenuLogo"
                    />
                ),
            },
        ],
    },
    // {
    //     label: 'Machine Learning',
    //     key: 'ml_admin',
    //     title: 'Machine Learning',
    //     icon: <img src={MLAdmin} alt='MLAdmin' className='sideMenuLogo' />,
    //     children: [
    //         {
    //             label: 'DPK',
    //             key: 'ml_admin/dpk',
    //             title: 'DPK',
    //             icon: <img src={DPK} alt='DPK' className='sideMenuLogo' />,
    //         },
    //         {
    //             label: 'LPP',
    //             key: 'ml_admin/lpp',
    //             title: 'LPP',
    //             icon: <img src={LPP} alt='LPP' className='sideMenuLogo' />,
    //         },
    //         {
    //             label: 'LCP',
    //             key: 'ml_admin/lcp_trained',
    //             title: 'LCP',
    //             icon: <img src={LCPTrained} alt='LCPTrained' className='sideMenuLogo' />,
    //         },
    //         // {
    //         //     label: 'Back Test',
    //         //     key: 'ml_admin/backtest',
    //         //     title: 'Back Test',
    //         //     icon: <img src={backTest} alt='backTest' className='sideMenuLogo' />,
    //         // },
    //         // {
    //         //     label: 'Ghost Scheduler',
    //         //     key: 'ml_admin/ghost_scheduler',
    //         //     title: 'Ghost Scheduler',
    //         //     icon: <img src={ghostScheduler} alt='ghostScheduler' className='sideMenuLogo' />,
    //         // },
    //         {
    //             label: 'LCP Performance',
    //             key: 'ml_admin/lcp_performance',
    //             title: 'LCP Performance',
    //             icon: <img src={lcpPerformance} alt='lcpPerformance' className='sidemenulogo' />,
    //         },
    //     ],
    // },
    // {
    //     label: 'Predictive Alarms',
    //     key: 'predictive_alarms_admin',
    //     title: 'Predictive Alarms',
    //     icon: <img src={predictiveAlarmsAdmin} alt='predictiveAlarms' className='sideMenuLogo' />,
    //     children: [
    //         {
    //             label: 'Monitor',
    //             key: 'predictive_alarms_admin/monitor',
    //             title: 'Monitor',
    //             icon: <img src={predictiveAlarmsMonitor} alt='predictiveAlarmsMonitor' className='sideMenuLogo' />,
    //         },
    //     ],
    // },
    // {
    //     label: 'Monitor Stack',
    //     key: 'monitor_stack',
    //     title: 'Monitor Stack',
    //     icon: <img src={monitorStack} alt='monitorStack' className='sideMenuLogo' />,
    // },
];

const LeftNav = ({
    onExpandMenu = () => {},
    openKeys,
    visibleSideMenu,
    mobileCollapse,
    mobile,
    onCollapseMenu = () => {},
    selectedKey,
}) => {
    return (
        <div
            className={`${
                visibleSideMenu ? "alignLeftNavCollapse" : "alignLeftNav"
            } ${mobileCollapse ? "mobileCollpase" : ""}`}
        >
            <Sider
                width={"15vw"}
                breakpoint="lg"
                collapsible
                collapsed={visibleSideMenu}
                onCollapse={onCollapseMenu}
            >
                {mobile ? (
                    <a href="/">
                        <img
                            src={LivelineMobile}
                            className="logo"
                            alt="livelinelog"
                        />
                    </a>
                ) : (
                    <a href="/">
                        {visibleSideMenu ? (
                            <img
                                src={LivelineMobile}
                                className="logo"
                                alt="livelinelog"
                            />
                        ) : (
                            <img
                                src={Liveline}
                                className="logo"
                                alt="livelinelog"
                            />
                        )}
                    </a>
                )}

                <Menu
                    style={{
                        maxHeight: "calc(100vh - 117px)",
                        overflow: "auto",
                    }}
                    items={menuItems}
                    openKeys={openKeys}
                    onOpenChange={onExpandMenu}
                    defaultSelectedKeys={[selectedKey]}
                />
            </Sider>
        </div>
    );
};

export default LeftNav;
